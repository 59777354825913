<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <img src="/img/logo/Logo-UNJ.png" alt="" height="100" />
            </div>

            <div class="d-flex justify-content-center">
              <h4>Sistem Administrasi Umum</h4>
            </div>
            <div class="d-flex justify-content-center">
              <h4>
                Program Studi Fisika, Pendidikan Fisika, dan Magister Pendidikan
                Fisika
              </h4>
            </div>
            <hr style="height: 1px; background: black" />

            <!-- <ul class="undot">
              <li>Nama Mahasiswa : {{ detail.student_name }}</li>
              <li>NIM : {{ detail.registration_number }}</li>
              <li>Semester ke- : {{ detail.semester_name }}</li>
              <li>Tahun ke- : {{ detail.year_id }}</li>
              <li>Program Studi : {{ detail.study_program_name }}</li>
              <li>Fakultas : {{ detail.faculty_name }}</li>
              <li>Alamat : {{ detail.address }}</li>
              <li>No. Telp/Wa : {{ detail.phone }}</li>
              <li>Alamat Email : {{ detail.email }}</li>
              <li>Jenis Surat : Surat Permohonan Peminjaman Alat Non-Laboratorium</li>
              
              <li>Nama Kegiatan : {{ detail.activity_name }}</li>
              <li>
                  <div class="d-flex">
                      <span>Alat yang akan dipinjam : </span>
                        <ol class="ml-3">
                            <li v-for="tool in detail.tools_display" :key="tool.id">{{tool.name}} ({{tool.amount}})</li>
                        </ol>
                  </div>
              </li>
              <li>Waktu Peminjaman : {{ setStartTime(detail.start_time) }}</li>
              <li>Dosen Pembimbing : {{ detail.teacher_mentor_name }}</li>
              
              <li>Keterangan : {{ detail.description }}</li>
            </ul> -->
            <table class="table table-borderless">
              <tr style="line-height: 1">
                <td>Nama Mahasiswa</td>
                <td>:</td>
                <td>{{ detail.student_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>NIM</td>
                <td>:</td>
                <td>{{ detail.student_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Semester ke-</td>
                <td>:</td>
                <td>{{ detail.registration_number }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Tahun ke-</td>
                <td>:</td>
                <td>{{ detail.year_id }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Program Studi</td>
                <td>:</td>
                <td>{{ detail.study_program_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Fakultas</td>
                <td>:</td>
                <td>{{ detail.faculty_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat</td>
                <td>:</td>
                <td>{{ detail.address }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>No. Telp/Wa</td>
                <td>:</td>
                <td>{{ detail.address }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat Email</td>
                <td>:</td>
                <td>{{ detail.email }}</td>
              </tr>
              <!--  -->
              <tr style="line-height: 1">
                <td>Jenis Surat</td>
                <td>:</td>
                <td>Surat Permohonan Peminjaman Alat Non-Laboratorium</td>
              </tr>
              <tr style="line-height: 1">
                <td>Nama Kegiatan</td>
                <td>:</td>
                <td>{{ detail.activity_name }}</td>
              </tr>
              <tr>
                <td>Alat yang akan dipinjam</td>
                <td>:</td>
                <td>
                  <ol class="ml-3">
                    <li v-for="tool in detail.tools_display" :key="tool.id">{{tool.name}}</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>Waktu Peminjaman</td>
                <td>:</td>
                <td>{{ setStartTime(detail.start_time) }}</td>
              </tr>
              <!--  -->
              <tr style="line-height: 1">
                <td>Keterangan</td>
                <td>:</td>
                <td>{{ detail.description }}</td>
              </tr>
            </table>
            <!-- <div class="d-flex justify-content-start mt-2">
              <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  image-container
                "
              >
                <div class="image-input-wrapper" style="position: relative">
                  <img class="image" :src="detail.sign" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object,
  },
  methods:{
      setStartTime(time){
        let date = new Date(time);
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        console.log(date.toLocaleTimeString('id-ID'));        
        return date.toLocaleDateString('id-ID', options) + " " + date.toLocaleTimeString('id-ID')
    }
  }
};
</script>

<style scoped>
.undot li {
  list-style-type: none;
}

.image-input-wrapper {
  width: 130px !important;
  height: 130px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>